import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import VueCarousel from 'vue-carousel';

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";

import { initFirebaseBackend } from './helpers/firebase/authUtils';

import { configureFakeBackend } from './helpers/fakebackend/fake-backend';
console.log(process.env.VUE_APP_APIKEY)
const firebaseConfig = {
  apiKey: "AIzaSyBZ98z2xHd9TMv3yXwLY-ZIQkdCSp_1jKM",
  authDomain: "membresia-integra.firebaseapp.com",
  projectId: "membresia-integra",
  storageBucket: "membresia-integra.appspot.com",
  messagingSenderId: "320051284896",
  appId: "1:320051284896:web:8bc93efdf7ca87d9e2f2c5"
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)
Vue.use(VueCarousel)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
