import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/categorias',
        name: 'categorias',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/categorias')
    },
    {
        path: '/descuentos',
        name: 'descuentos',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/descuentos')
    },
    {
        path: '/client/:name/brands/:id',
        name: 'brands',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/brands')
    },
    {
        path: '/brand/:name/:id',
        name: 'brand',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/brand')
    },
    {
        path: '/brand/content-planning/:name/:type/:month/:year/:id',
        name: 'content-planning',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/content_planning')
    },
    {
        path: '/share/content-planning/:name/:type/:idContent/:id',
        name: 'share-content',
        meta: {
        },
        component: () => import('../views/pages/dashboard/share_content')
    },
    {
        path: '/subcategorias',
        name: 'subcategorias',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/subcategorias')
    },
    {
        path: '/empresas',
        name: 'empresas',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/empresas')
    },
    {
        path: '/register/user',
        name: 'register-user',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/register')
    },
    {
        path: '/admin',
        name: 'admin-user',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/admin')
    },
]